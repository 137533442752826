export const LANDING = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const HOME = '/home';
export const FAQ = '/faq';
export const RETURN_ACTION = '/return-action';
export const PASSWORD_FORGET = '/pw-forget';

export const DASHBOARD = '/';
export const REPS = '/reps';
export const USER_SUPPORT = '/user-support';
