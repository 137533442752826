import { selector } from 'recoil';
import { userAtomId } from './atoms';
import { fetchUser } from 'api/services/Firebase';

export const userAtomState = selector({
  key: 'userAtomState',
  get: async ({ get }) => {
    const userId = get(userAtomId);
    const userData = await fetchUser(userId);
    return { userId, userData };
  },
});

export const currentUserState = selector({
  key: 'currentUserState',
  get: async ({ get }) => {
    //const userId = get(userAtomId);
    const userData = await fetchUser(get(userAtomId));
    return userData;
  },
});
