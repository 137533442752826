import { rgba } from 'polished';
const palette = {
  icon: {
    0: '#',
    1: '6',
    2: '6',
    3: '6',
    4: '6',
    5: '6',
    6: '6',
    dark: '#f8f8f8',
    light: '#666666',
  },
  active: 'rgba(221,221,221,0.5)',
  black: '#000000',
  border: 'rgba(0,0,0,0.33)',
  contrast: '#f8f8f8',
  brand: '#333333',
  'brand-contrast': '#f8f8f8',
  accent: '#76CEE5',
  'accent-contrast': '#f8f8f8',

  control: {
    dark: '#00A8F0',
    light: '#0096D6',
  },
  focus: '#99d5ef',
  placeholder: '#AAAAAA',
  selected: 'brand',
  text: {
    dark: '#f8f8f8',
    light: '#333333',
  },
  white: '#FFFFFF',

  'accent-1': rgba(29, 71, 151, 1),
  'accent-2': '#E6C84B',
  'accent-3': '#915591',
  'accent-4': '#FFCA58',
  'dark-0': '#000000',
  'dark-1': '#000001',
  'dark-2': '#676767',
  'dark-3': '#777777',
  'dark-4': '#999999',
  'dark-5': '#999999',
  'dark-6': '#999999',
  'light-0': '#ffffff',
  'light-1': '#F2F2F2',
  'light-2': '#E8E8E8',
  'light-3': '#CCCCCC',
  'light-4': '#DADADA',
  'light-5': '#DADADA',
  'light-6': '#DADADA',
  'neutral-1': '#006996',
  'neutral-2': '#A65336',
  'neutral-3': '#A69136',
  'neutral-4': '#774677',
  'status-critical': '#F04B37',
  'status-error': rgba(255, 64, 64, 1),
  'status-warning': '#F0AA3C',
  'status-ok': '#509137',
  'status-unknown': '#848484',
  'status-disabled': '#848484',
  background: '#ffffff',
  'global-default-main': rgba(51, 51, 51, 1),
  'global-default-contrast': '#f8f8f8',
  'global-default-hover': rgba(51, 51, 51, 0.5),
  'global-primary-main': rgba(29, 71, 151, 1),
  'global-primary-contrast': '#f8f8f8',
  'global-primary-hover': rgba(29, 71, 151, 0.5),
  'global-secondary-main': rgba(118, 206, 229, 1),
  'global-secondary-contrast': '#f8f8f8',
  'global-secondary-hover': rgba(118, 206, 229, 0.5),
  'global-brand-1': rgba(51, 51, 51, 1),
  'global-accent-1': rgba(29, 71, 151, 1),
  'global-accent-2': rgba(29, 71, 151, 0.7),
  'global-accent-3': rgba(119, 70, 119, 0.8),
  'global-background-0': rgba(255, 255, 255, 1),
  'global-background-1': rgba(29, 71, 151, 0.05),
  'modal-background-1': rgba(29, 71, 151, 0.05),
  'global-text-1': '#f8f8f8',
  'express-brand-1': rgba(118, 206, 229, 1),
  'express-accent-1': rgba(41, 42, 103, 1),
  'elite-accent-1': rgba(157, 30, 34, 1),
  'elite-brand-1': rgba(237, 36, 38, 1),
  'eliteAssoc-brand-1': rgba(242, 160, 30, 1),
  'eliteAssoc-accent-1': rgba(229, 64, 37, 1),
  'express-brand-hover': rgba(118, 206, 229, 0.4),
  'elite-brand-hover': rgba(242, 160, 30, 0.4),
  'eliteAssoc-brand-hover': rgba(237, 36, 38, 0.4),
  'express-brand-text': '#f8f8f8',
  'elite-brand-text': '#f8f8f8',
  'eliteAssoc-brand-text': '#f8f8f8',
  'form-back': '#ffffff',
  'chat-icon': '#ffffff',
  'chat-icon-back': '#F1A020',
  'chat-header-back': rgba(29, 71, 151, 0.8),
  'chat-holder-back': rgba(255, 255, 255, 0.95),
  'chat-holder-border': rgba(29, 72, 151, 1),
  'chat-holder-contrast': '#999999',
  'chat-button-main': rgba(118, 206, 229, 1),
  'chat-button-contrast': rgba(29, 71, 151, 1),
  'chat-botbubble-main': rgba(29, 71, 151, 0.3),
  'chat-botbubble-contrast': '#000000',
  'chat-userbubble-main': rgba(255, 255, 255, 1),
  'chat-userbubble-contrast': '#000000',
};
export default palette;
