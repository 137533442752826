import { grommet, deepMerge } from 'grommet/utils';
import theme from './eaRepInfoTheme';

import palette from './palette';
import {
  breakpoints,
  deviceView,
  mediaMin,
  breakWidths,
  mediaMinDevice,
  uiConstants,
} from './responsive';
export const appTheme = deepMerge(grommet, theme);
export { palette as colors };
export {
  breakpoints,
  deviceView,
  mediaMin,
  breakWidths,
  mediaMinDevice,
  uiConstants,
};
