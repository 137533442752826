import React from 'react';
import styled from 'styled-components';
import { useTable, useFilters, useFlexLayout, useSortBy } from 'react-table';

import { GoVerified } from 'react-icons/go';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { VscUnverified } from 'react-icons/vsc';
import { Box } from 'grommet';
import { matchSorter } from 'match-sorter';

const PageBox = styled(Box)`
  max-width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  direction: 'column';
  border-bottom: 1px solid black;
`;

const TableFlex = styled(Box)`
  display: flex;
  width: 100%;
  border-spacing: 0;
  border: 1px solid black;
`;

const TableFlexHeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.global.colors.light};
`;
const TableFlexHeaderGroup = styled.div``;
const TableFlexColHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #cccccc;
  color: #282965;
  font-weight: 800;
  margin: 0;
  padding: 0.5rem;

  border-right: 1px solid #282965;
  &:last-child {
    border-right: 0;
  }
`;
const TableFlexBody = styled.div``;

const TableFlexRow = styled.div`
  &:first-child {
    border-top: 1px solid #282965;
  }
  &:last-child {
    .td {
      border-bottom: 0;
    }
  }
  border-bottom: 1px solid #282965;
`;

const TableFlexCell = styled.div`
  background: #ffffff;
  color: #282965;
  margin: 0;
  padding: 0.5rem;
  border-right: 1px solid #5a6268;

  &:last-child {
    border-right: 0;
  }
`;

const FilterInput = styled.div`
  width: 180px;
`;

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FilterInput>
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </FilterInput>
  );
}

const defaultPropGetter = () => ({});

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function ReactTable({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      myboolean: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? rowValue.toString() === filterValue
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    prepareRow,
    rows,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.isVisible === false)
          .map((col) => col['accessor']),
      },
    },
    useFilters,
    useSortBy,
    //useExpanded,
    useFlexLayout
    // useFilters!
    // useGlobalFilter // useGlobalFilter!
  );

  return (
    <PageBox>
      <TableWrapper>
        <TableFlex fill {...getTableProps()}>
          <TableFlexHeaderWrapper>
            {headerGroups.map((headerGroup) => (
              <TableFlexHeaderGroup {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableFlexColHeader
                    {...column.getHeaderProps(column.getSortByToggleProps(), [
                      { style: column.style },
                    ])}
                  >
                    <Box direction="row" justify="between" align="center">
                      {column.render('Header')}{' '}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown size="1.5rem" />
                        ) : (
                          <FaSortUp size="1.5rem" />
                        )
                      ) : (
                        <FaSort size="1.5rem" />
                      )}
                    </Box>
                    <span>
                      {column.canFilter ? column.render('Filter') : null}
                    </span>
                  </TableFlexColHeader>
                ))}
              </TableFlexHeaderGroup>
            ))}
          </TableFlexHeaderWrapper>
          <TableFlexBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              const rowProps = row.getRowProps(row.getRowProps());
              return (
                <React.Fragment key={rowProps.key}>
                  <TableFlexRow {...rowProps}>
                    {row.cells.map((cell) => {
                      return (
                        <TableFlexCell
                          {...cell.getCellProps([
                            { style: cell.column.style },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render('Cell')}
                        </TableFlexCell>
                      );
                    })}
                  </TableFlexRow>
                </React.Fragment>
              );
            })}
          </TableFlexBody>
        </TableFlex>
      </TableWrapper>
    </PageBox>
  );
}

function TableList({ data, loadItem, loadPage }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'displayName',
        accessor: 'displayName',
        isVisible: true,
        width: 250,
        filter: 'fuzzytext',
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: 'email',
        isVisible: true,
        width: 300,
        filter: 'fuzzytext',
      },
      {
        Header: 'Verified',
        accessor: 'emailVerified',
        Cell: ({ cell: { value } }) => {
          return (
            <Box justify="center" align="center">
              {value === true ? <GoVerified /> : <VscUnverified />}
            </Box>
          );
        },
        width: 60,
        isVisible: true,

        disableFilters: true,
      },

      {
        Header: 'AddedOn',
        id: 'creationTime',
        accessor: 'creationTime',
        width: 100,
        isVisible: true,

        disableFilters: true,
      },

      {
        Header: 'LastOn',
        id: 'lastRefreshTime',
        accessor: 'lastRefreshTime',
        width: 100,
        isVisible: true,

        disableFilters: true,
      },

      {
        Header: 'eaId ',
        id: 'eaId',
        accessor: 'eaId',
        isVisible: false,
      },
    ],
    []
  );

  return (
    <ReactTable
      columns={columns}
      data={data}
      getHeaderProps={(column) => ({})}
      getColumnProps={(column) => ({})}
      getRowProps={(row) => ({
        style: {
          background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
        },
      })}
      getCellProps={(cellInfo) => ({
        style: {
          backgroundColor: '#eeeeee',
        },
      })}
    />
  );
}

export default TableList;
