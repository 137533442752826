import React from 'react';
import { Router } from '@reach/router';

import { useNavigate } from '@reach/router';
// import styled from 'styled-components/macro';
import { Box } from 'grommet';

import Dashboard from 'pages/Dashboard';
import Reps from 'pages/Reps';
import UserSupport from 'pages/UserSupport';

import SideMenu from 'components/SideMenu';

import AdminAppHeader from 'components/AdminAppHeader';

// const VerifiedRoute = ({ as: Component, ...props }) => {
//   const { ...rest } = props;
//   return props.isVerified ? (
//     <Component {...rest} />
//   ) : (
//     <Redirect path="/user" to="/user" noThrow />
//   );
// };

// const CollapsibleSidebar = styled(Collapsible)`
//   min-width: 80px;
// `;

const PrivateRoutes = (props) => {
  const navigate = useNavigate();
  const navTo = (slug) => {
    console.log(`slug ${slug}`);
    navigate(slug);
  };

  return (
    <Box fill>
      <AdminAppHeader isPrivate={true} />
      <Box direction="row" flex fill>
        <SideMenu menuAction={navTo} />
        <Box flex fill>
          <Router>
            <Dashboard path="/" />
            <UserSupport path="/user-support" />
            <Reps path="/reps" />
          </Router>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateRoutes;
