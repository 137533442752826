import styled from 'styled-components/macro';
import { Box, Markdown } from 'grommet';
import { breakWidths } from 'styles';

import { colors } from 'styles';

/**
 *  Typography
 *
 * 16
 *  font-size: 1.0rem;
 *  line-height: 1.2rem;
 *
 *  18
 *  font-size: 1.125rem
 *  line-height: 1.325rem;
 *
 *  24
 *  font-size: 1.5rem;
 *  line-height: 1.8rem;
 *
 *  36
 *  font-size: 2.25rem;
 *  line-height: 2.7rem;
 *
 *  48
 *  font-size: 3rem;
 *  line-height: 3.6rem
 */

/**
 *  SPACERS
 *
 *  6
 *  0.375rem
 *
 *  12
 *  0.75rem
 *
 *  24
 *  1.5rem
 *
 *  48
 *  3rem
 *
 *  64
 *  4rem
 *
 */

// const ProgramPageCardGrid = styled.div`
//   display: grid;
//   grid-template-columns: [center-start] auto [center-end];

//   margin: 0 0.75rem 0.75rem 0.75rem;
//   /*** tablet ***/
//   @media (min-width: ${breakWidths.md}) {
//     margin: 0 0.75rem 1.5rem 0.75rem;
//   }
//   box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
// `;
// const ProgramPageCard = styled.div`
//   grid-column: center-start;
//   overflow: auto;
//   display: flex;
// `;
// const ProgramPageCards = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
// `;

const ProgramGrid = styled.div`
  display: grid;
  /* justify-content: center; */
  grid-template-columns: [mg-l-1] auto [mg-lc-1] 8fr [mg-rc-1] auto [mg-r-1];

  grid-template-rows: 1fr;
  grid-template-areas:
    '. hero .'
    '. info1 . '
    '. support . ';
`;

const ProgramRowBox = styled(Box)`
  display: grid;
  justify-items: center;
  grid-template-columns: [outer-l] 1.5rem [center-col-start] auto [center-col-end] 1.5rem [outer-r];
  grid-template-rows: 1fr;
  background: ${colors['global-background-1']};
  @media (min-width: ${breakWidths.sm}) {
    grid-template-columns: [outer-l] 3rem [center-col-start] auto [center-col-end] 3rem [outer-r];
  }
  @media (min-width: ${breakWidths.lg}) {
    grid-template-columns: [outer-l] auto [center-col-start] 56rem [center-col-end] auto [outer-r];
  }
  @media (min-width: ${breakWidths.xl}) {
    grid-template-columns: [outer-l] auto [center-col-start] 66rem [center-col-end] auto [outer-r];
  }
`;

const ProgramBoxCenterGrid = styled(Box)`
  grid-column-start: center-col-start;
  grid-column-end: center-col-end;
  grid-row-start: 1;
  grid-row-end: 2;
  align-items: center;
`;

// const ProgramHeading = styled(Heading)`
//   font-weight: 400;
//   margin: 0px;
//   font-size: 1.125rem;
//   line-height: 1.35rem;
//   max-width: 48rem;
//   text-align: center;
//   @media (min-width: ${breakWidths.md}) {
//     font-size: 1.5rem;
//     line-height: 1.8rem;
//     max-width: 60rem;
//   }
// `;
const ProgramHeading2 = styled.h2`
  font-family: Lato;
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-size: 1.5rem;
  line-height: 1.8rem;
  max-width: 100%;
  font-weight: 800;
  text-align: center;
  margin: 0;
  max-width: 48rem;
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
  @media (min-width: ${breakWidths.lg}) {
    font-size: 2.25rem;
    line-height: 2.7rem;
  }
`;
const ProgramHeading3 = styled.h3`
  white-space: pre-wrap;
  font-family: Lato;
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.35rem;
  text-align: center;
  font-weight: 400;
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.2rem;
    line-height: 1.35rem;
    text-align: center;
    font-weight: 400;
    max-width: 60rem;
  }
  @media (min-width: ${breakWidths.lg}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    font-weight: 400;
    max-width: 64rem;
  }
`;

const ProgramHeading4 = styled.h4`
  font-family: Lato;
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.35rem;
  text-align: center;
  font-weight: 400;
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.2rem;
    line-height: 1.35rem;
    text-align: center;
    font-weight: 800;
    max-width: 60rem;
  }
  @media (min-width: ${breakWidths.lg}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    font-weight: 400;
    max-width: 64rem;
  }
`;

const ProgramPageHero = ({ content }) => {
  return (
    <ProgramBoxCenterGrid
      direction="column"
      pad={{ horizontal: 'none', vertical: 'large' }}
      align="center"
      gap="medium"
    >
      <Box
        fill
        width={{ min: '200px', max: '280px' }}
        pad={{ horizontal: 'none', vertical: 'none' }}
        justify="center"
        align="center"
      >
        {content.banner}
      </Box>
      <ProgramHeading3>{content.copy}</ProgramHeading3>
    </ProgramBoxCenterGrid>
  );
};

const PageGrid = styled.div`
  display: grid;
  /* justify-content: center; */
  grid-template-columns: [pg-l-1] auto [pg-lc-1] 8fr [pg-rc-1] auto [pg-r-1];

  grid-template-areas:
    '. intro . '
    '. program . '
    '. support . ';
`;

const PageGridRow = styled(Box)`
  display: grid;
  justify-content: center;
  grid-template-columns: [outer-l] 1.5rem [center-col-start] auto [center-col-end] 1.5rem [outer-r];
  grid-template-rows: 1fr;
  background: ${colors['global-background-1']};
  @media (min-width: ${breakWidths.sm}) {
    grid-template-columns: [outer-l] 3rem [center-col-start] auto [center-col-end] 3rem [outer-r];
  }
  @media (min-width: ${breakWidths.lg}) {
    grid-template-columns: [outer-l] auto [center-col-start] 56rem [center-col-end] auto [outer-r];
  }
  @media (min-width: ${breakWidths.xl}) {
    grid-template-columns: [outer-l] auto [center-col-start] 64rem [center-col-end] auto [outer-r];
  }
`;
const PageGridCenter = styled(Box)`
  grid-column-start: center-col-start;
  grid-column-end: center-col-end;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const PageHeading2 = styled.h2`
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `800`)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  font-size: 1.5rem;
  line-height: 1.8rem;
  max-width: 100%;
  margin: 0;
  white-space: pre-wrap;
  @media (min-width: ${breakWidths.sm}) {
    white-space: normal;
  }
  @media (min-width: ${breakWidths.md}) {
    white-space: normal;
    font-family: Lato;
    font-size: 2.25rem;
    line-height: 2.7rem;
    text-align: ${(props) => (props.textAlignLg ? props.textAlignLg : 'left')};
  }
`;

const PageHeading3 = styled.h3`
  font-family: Lato;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.35rem;
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `800`)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: ${(props) => (props.textAlignLg ? props.textAlignLg : 'left')};
  }
`;
const PageCopy = styled.p`
  /* font-size: 18px */
  margin: 0;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.35rem;
  max-width: 50rem;
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `400`)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  white-space: pre-wrap;
  @media (min-width: ${breakWidths.sm}) {
    white-space: normal;
  }
`;
const PageCopyXs = styled.p`
  margin: 0;
  font-weight: 400;
  /* font-size: 16px */
  font-size: 1rem;
  line-height: 1.2rem;
  max-width: 100%;
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `400`)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.125rem;
    line-height: 1.35rem;
    text-align: ${(props) =>
      props.textAlignLg ? props.textAlignLg : 'center'};
    max-width: 50rem;
  }
`;
const PageCopyMd = styled(Markdown)`
  color: ${(props) => (props.textColor ? props.textColor : '#333')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `400`)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.35rem;
  max-width: 50rem;
  &&& > a {
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : `400`)};
  }
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: ${(props) =>
      props.textAlignLg ? props.textAlignLg : 'center'};
  }
`;

const PageIntroBanner = styled.div`
  display: flex;
  flex-direction: column;
`;

// ProgramPageCardGrid,
// ProgramPageCards,
// ProgramPageCard,
export {
  PageGrid,
  PageGridRow,
  PageGridCenter,
  PageIntroBanner,
  ProgramBoxCenterGrid,
  ProgramRowBox,
  ProgramGrid,
  ProgramPageHero,
  ProgramHeading2,
  ProgramHeading3,
  ProgramHeading4,
  PageHeading2,
  PageHeading3,
  PageCopy,
  PageCopyXs,
  PageCopyMd,
};
