import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Controller } from 'react-hook-form';
import { Anchor, Stack, Button, Box, MaskedInput, TextInput } from 'grommet';
import { Hide, View } from 'grommet-icons';
import { colors, breakWidths } from 'styles';
import { SpinnerOverlay } from 'components/Spinner';

const MaskedInputField = styled(MaskedInput)`
  &&&& ::placeholder {
    font-family: Lato;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 400;
    color: #ffffff;
    box-sizing: border-box;
    border: none;
    -webkit-appearance: none;
    background: transparent;
    width: 100%;

    border: 0px solid rgba(0, 0, 0, 0.33);
    border-radius: 0px;
  }
`;
// const MaskedEmailInput = styled(MaskedInput)`
//   &&&& ::placeholder {
//     font-family: Lato;
//     font-size: 1.125rem;
//     line-height: 1.35rem;
//     font-weight: 400;
//     color: #ffffff;
//     box-sizing: border-box;
//     border: none;
//     -webkit-appearance: none;
//     background: transparent;
//     width: 100%;

//     border: 0px solid rgba(0, 0, 0, 0.33);
//     border-radius: 0px;
//   }
// `;
const emailMask = [
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'username',
  },
  { fixed: '@' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'excelerateamerica',
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com',
  },
];

const phoneMask = [
  { fixed: '(' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: ')' },
  { fixed: ' ' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: '-' },
  {
    length: 4,
    regexp: /^[0-9]{1,4}$/,
    placeholder: 'xxxx',
  },
];
// const FormTextFinePrint = styled(Markdown)`
//   font-size: 1rem;
//   font-weight: 400;
//   color: #ffffff;
//   > a {
//     color: #ffffff;
//     font-weight: 400;
//   }
// `;

const FormFieldTextError = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${colors['status-error']};
  font-weight: 400;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
`;

const FieldTextApiErrors = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${colors['status-error']};
  font-weight: 400;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
`;

const FormFieldLabel = styled.label`
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.05rem;
  color: #ffffff;
  font-weight: 400;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`;
const FormFieldInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #ffffff;
  /* margin-bottom: 1.5rem; */
`;
const FormFieldInputSide = styled.div`
  flex: 1 0;
`;

/** Custom Password Form Field */
const PasswordTextInput = styled(TextInput)`
  border: 0px solid rgba(0, 0, 0, 0.33);
  box-shadow: none;
`;

const FieldInputText = (props) => {
  return (
    <Controller
      defaultValue={props.defaultValue}
      render={({ onChange, onBlur, value }) => (
        <TextInput onChange={onChange} onBlur={onBlur} selected={value} />
      )}
      {...props}
    />
  );
};
const FieldInputPhone = (props) => {
  return (
    <Controller
      render={({ onChange, onBlur, value }) => (
        <MaskedInputField
          mask={phoneMask}
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
        />
      )}
      {...props}
    />
  );
};
const FieldInputEmail = (props) => {
  return (
    <Controller
      render={({ onChange, onBlur, value }) => (
        <MaskedInputField
          mask={emailMask}
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
        />
      )}
      {...props}
    />
  );
};
const FieldInputPassword = ({ reveal, ...props }) => {
  return (
    <Controller
      render={({ onChange, onBlur, value }) => (
        <PasswordTextInput
          type={reveal ? 'text' : 'password'}
          onChange={onChange}
          onBlur={onBlur}
          selected={value}
        />
      )}
      {...props}
    />
  );
};

const FormFieldResponsiveWrapper = styled(Box)``;

const FormFieldCustom = ({ label, fieldInput, fieldBtn, fieldError }) => {
  return (
    <FormFieldWrapper>
      <FormFieldLabel>{label}</FormFieldLabel>
      <FormFieldInputWrapper>
        <FormFieldInputSide>{fieldInput}</FormFieldInputSide>
        {fieldBtn}
      </FormFieldInputWrapper>
      {fieldError}
    </FormFieldWrapper>
  );
};

const FormFieldPassword = ({ label, name, control }) => {
  const [reveal, setReveal] = useState(false);

  return (
    <Box>
      <FormFieldLabel>{label}</FormFieldLabel>
      <FormFieldWrapper>
        <FormFieldInputSide>
          <Controller
            render={({ onChange, onBlur, value }) => (
              <PasswordTextInput
                type={reveal ? 'text' : 'password'}
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
              />
            )}
          />
        </FormFieldInputSide>
        <EyeButton
          plain
          icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
          onClick={() => setReveal(!reveal)}
        />
      </FormFieldWrapper>
    </Box>
  );
};
const EyeButton = styled(Button)`
  flex: 0 0 24px;
`;
const PasswordRevealBtn = ({ reveal, onClick }) => {
  return (
    <EyeButton
      plain
      icon={reveal ? <View size="medium" /> : <Hide size="medium" />}
      onClick={onClick}
    />
  );
};

const FormAnchorText = styled.span`
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 1.5rem;
`;
const FormAnchor = styled(Anchor)`
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  text-decoration: underline;

  &:hover {
    color: #ffffff;
    font-weight: 800;
    text-decoration: underline;
  }
`;

const BtnSignIn = () => {
  return (
    <FormAnchorText>
      Already have an account?{'  '}
      <FormAnchor href="/landing/signin" label="Log in." />
    </FormAnchorText>
  );
};
const BtnSignUp = () => {
  return (
    <FormAnchorText>
      Don’t have an account?{'  '}
      <FormAnchor href="/landing/signup" label="Create one now." />
    </FormAnchorText>
  );
};
const BtnForgotPassword = () => {
  return (
    <FormAnchorText>
      Forgot Password?{'  '}
      <FormAnchor href="/landing/password-reset" label="Reset." />
    </FormAnchorText>
  );
};

const BtnPrivacyNotice = () => {
  return (
    <FormAnchorText>
      By signing up you agree to our{' '}
      <FormAnchor
        href="https://info.excelerateamerica.com/exceleratebiz-blog-privacy-policy/terms-and-conditions-0"
        label="Privacy Notice."
        target="_blank"
      />
    </FormAnchorText>
  );
};
const PublicBtnsGroup = ({ screenType }) => {
  if (screenType === 'admin') {
    return <Box justify="start" />;
  }
  if (screenType === 'return-password' || screenType === 'password-reset') {
    return (
      <Box justify="start">
        <BtnSignIn />
      </Box>
    );
  }
  if (screenType === 'signup') {
    return (
      <Box justify="start">
        <BtnSignIn />
        <BtnPrivacyNotice />
      </Box>
    );
  }
  return (
    <Box justify="start">
      <BtnForgotPassword />

      <BtnSignUp />
    </Box>
  );
};

const SubmitBtn = ({ isWorking, label, ...other }) => {
  return (
    <Stack anchor="center">
      <Button
        type="submit"
        secondary
        label={label ? label : 'submit'}
        {...other}
      />
      {isWorking && (
        <Box round="0.4rem">
          <SpinnerOverlay />
        </Box>
      )}
    </Stack>
  );
};
const FormCtaBtnSecondary = ({ isWorking, label, ...other }) => {
  return (
    <Stack anchor="center">
      <Button secondary label={label ? label : 'submit'} {...other} />
      {isWorking && (
        <Box round="0.4rem">
          <SpinnerOverlay />
        </Box>
      )}
    </Stack>
  );
};
const FormCtaBtn = ({ isWorking, label, ...other }) => {
  return (
    <Stack anchor="center">
      <Button primary label={label ? label : 'submit'} {...other} />
      {isWorking && (
        <Box round="0.4rem">
          <SpinnerOverlay />
        </Box>
      )}
    </Stack>
  );
};
const FormBox = styled(Box)`
  margin: 1.5rem 0;
`;
const FormSubmitBox = styled(Box)`
  margin: 1.5rem 0 0 0;
`;
const FormCtaBox = styled(Box)`
  margin: 1.5rem 0 0 0;
`;

const FormErrorsBox = styled(Box)``;

const FormHeader = styled.h2`
  white-space: pre-wrap;
  font-size: 1.5rem;
  line-height: 1.8rem;
  max-width: none;
  margin: 0;
  font-weight: 800;
  @media (min-width: ${breakWidths.md}) {
    font-size: 2.25rem;
    line-height: 2.7rem;
    max-width: 1024px;
  }
`;
const FormCopy = styled.span`
  font-size: 1.125rem;
  line-height: 1.35rem;
  font-weight: 400;
  @media (min-width: ${breakWidths.md}) {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`;
const FormHeaderBox = styled(Box)``;
const FormHeaders = ({ content }) => {
  return (
    <FormHeaderBox gap="medium">
      <FormHeader>{content.header}</FormHeader>
      {content && content.copy && <FormCopy>{content.copy}</FormCopy>}
    </FormHeaderBox>
  );
};
const FormContent = ({ children }) => {
  return (
    <FormHeaderBox gap="medium">
      <FormCopy>{children}</FormCopy>
    </FormHeaderBox>
  );
};
export {
  FormHeaders,
  FormContent,
  FormCtaBtn,
  FormCtaBtnSecondary,
  FormCtaBox,
  FormBox,
  FormSubmitBox,
  FormErrorsBox,
  FieldInputText,
  FieldInputEmail,
  FieldInputPhone,
  FieldInputPassword,
  FieldTextApiErrors,
  FormFieldCustom,
  SubmitBtn,
  PublicBtnsGroup,
  FormFieldPassword,
  PasswordRevealBtn,
  FormFieldTextError,
  FormFieldResponsiveWrapper,
};
