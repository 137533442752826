import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

import { configProcess } from 'api/config';
// Initialize Firebase
firebase.initializeApp(configProcess);

const fbauth = firebase.auth();
const rtdb = firebase.database();
const fsdb = firebase.firestore();
const storage = firebase.storage();
const analytics = firebase.analytics();

const updateHelpContact = async (uid, contact) => {
  console.log(`uid=> ${uid}`);
  const authUser = fbauth.currentUser;
  const adminId = authUser.uid;
  const adminName = authUser.displayName;

  const updateObj = {
    ...contact,
    adminId,
    adminName,
    timestampUpdated: firebase.database.ServerValue.TIMESTAMP,
  };

  const helpContactRef = rtdb.ref('helpContacts').child(uid);
  return helpContactRef.update(updateObj);
};
//const provider = new firebase.auth.GoogleAuthProvider()
const fetchUser = async (uid) => {
  if (!uid) return;
  const userData = await fsdb.collection('users').doc(uid).get();
  return userData.data();
};
const updateUser = async ({ uid, userData }) => {
  const userDocRef = fsdb.collection('users').doc(uid);
  const newData = {
    ...userData,
    dateUpdated: new Date(),
  };
  await userDocRef.update(newData);
  return userDocRef.get();
};
const logSimpleEvent = (event) => {
  return analytics.logEvent(event);
};

const updateSupportUser = async ({ uid, userData }) => {
  const helpContactRef = rtdb.ref(`/helpContact`).child(uid);
  console.log(`uid ${uid}, userData: ${JSON.stringify(userData)}`);
  const newData = {
    ...userData,
    dateUpdated: new Date(),
  };
  await helpContactRef.update(newData);
};
const streamHelpContacts = () => {
  return rtdb.ref('/helpContacts');
};
const streamUserList = (observer) => {
  return fsdb.collection('users').orderBy('dateAdded').onSnapshot(observer);
};

const listAllAuthUsers = firebase.functions().httpsCallable('listAllAuthUsers');

export {
  fbauth,
  fsdb,
  analytics,
  updateHelpContact,
  logSimpleEvent,
  storage,
  fetchUser,
  updateUser,
  streamUserList,
  streamHelpContacts,
  listAllAuthUsers,
  updateSupportUser,
};
