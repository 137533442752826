import React, { Suspense } from 'react';
import styled from 'styled-components/macro';
import { Router } from '@reach/router';
import { Box } from 'grommet';
import AdminAppHeader from 'components/AdminAppHeader';
import { colors, breakWidths } from 'styles';
// import PublicCard from 'components/PublicCard';
import PublicSideTriangles from 'assets/patterns/PublicSideTriangles.svg';

import Spinner from 'components/Spinner';

const PublicBackArea = styled(Box)`
  background-color: ${colors['global-primary-main']};
  min-height: calc(100vh - 64px);
  overflow: hidden;
  @media (min-width: ${breakWidths.md}) {
    background-attachment: fixed;
    background-position: right -600px top;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${PublicSideTriangles});
  }

  /* @media (min-width: ${breakWidths.lg}) {
    background-position: right -100px top;
  } */
  @media (min-width: ${breakWidths.lg}) {
    background-position: right 0 top;
  }
  @media (min-width: ${breakWidths.xl}) {
    background-position: right 0 top;
  }
`;

const SignInForm = React.lazy(() => import('screens/SignInForm'));
const NotFound = React.lazy(() => import('screens/NotFound'));
const PublicRoutes = (props) => {
  return (
    <>
      <AdminAppHeader />
      <PublicBackArea>
        <Suspense fallback={<Spinner isLarge={true} />}>
          <Router>
            <SignInForm path="/" />
            <NotFound default />
          </Router>
        </Suspense>
      </PublicBackArea>
    </>
  );
};

export default PublicRoutes;
