import { atom } from 'recoil';

export const userAtomId = atom({
  key: 'userAtomId',
  default: null,
});

export const userAtom = atom({
  key: 'userAtom',
  default: null,
});
export const errorAtom = atom({
  key: 'errorAtom',
  default: null,
});
