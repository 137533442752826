import * as React from 'react';

function SvgLogoAppBarEaESq(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" {...props}>
      <path fill={props.fill} d="M0 0h180v180H0z" />
      <path
        fill="#f8a61e"
        fillRule="evenodd"
        d="M115.4 80.72L102 94.26l-13.39 13.53L83.7 89.3l-4.9-18.49 18.3 5z"
      />
      <path
        fill="#eb4626"
        fillRule="evenodd"
        d="M78.8 70.81l18.3 5 18.3 5-4.91-18.49-4.9-18.49L92.2 57.28z"
      />
      <path
        fill="#ed1626"
        fillRule="evenodd"
        d="M142.18 53.65l-13.39 13.53-13.39 13.54-4.91-18.49-4.9-18.49 18.29 4.95z"
      />
      <path
        fill="#f8a61e"
        fillRule="evenodd"
        d="M129.7 135.78l-13.38 13.53-13.4 13.54L98 144.36l-4.9-18.49 18.29 4.95 18.29 5z"
      />
      <path
        fill="#eb4626"
        fillRule="evenodd"
        d="M93.12 125.87l18.29 4.95 18.29 5-4.9-18.49-4.89-18.53-13.4 13.53z"
      />
      <path
        fill="#ed1626"
        fillRule="evenodd"
        d="M156.5 108.71l-13.4 13.53-13.4 13.54-4.9-18.49-4.89-18.49 18.29 5z"
      />
      <path
        fill="#0000dc"
        fillRule="evenodd"
        d="M60.09 66.56L55.2 48.07l-4.91-18.49 18.29 5 18.3 5L73.48 53z"
      />
      <path
        fill="#0001b4"
        fillRule="evenodd"
        d="M77.08 2.51l-13.4 13.54-13.39 13.53 18.29 5 18.3 5L82 21z"
      />
      <path
        fill="#01008c"
        fillRule="evenodd"
        d="M86.88 39.49L82 21 77.08 2.51l18.29 5 18.3 5L100.28 26z"
      />
      <path
        fill="#0000fe"
        fillRule="evenodd"
        d="M50.29 29.58L36.9 43.12 23.5 56.66l18.3 5 18.29 5-4.89-18.59z"
      />
      <path
        fill="#0128ff"
        fillRule="evenodd"
        d="M33.31 93.62L28.4 75.13l-4.9-18.47 18.3 5 18.29 5L46.7 80.09z"
      />
      <path
        fill="#0050ff"
        fillRule="evenodd"
        d="M60.09 66.56L46.7 80.09 33.31 93.62l18.3 5 18.29 5L65 85z"
      />
      <path
        fill="#1378fe"
        fillRule="evenodd"
        d="M43.11 130.6l-4.9-18.49-4.9-18.49 18.3 5 18.29 5-13.39 13.45z"
      />
      <path
        fill="#28a0ff"
        fillRule="evenodd"
        d="M69.9 103.53l-13.39 13.54-13.4 13.53 18.29 5 18.3 4.95L74.8 122z"
      />
      <path
        fill="#3cc7fe"
        fillRule="evenodd"
        d="M52.92 167.58L48 149.09l-4.89-18.49 18.29 5 18.3 4.95-13.39 13.5-13.39 13.53z"
      />
      <path
        fill="#50f0fe"
        fillRule="evenodd"
        d="M79.7 140.51l-13.39 13.54-13.39 13.53 18.29 4.95 18.3 5-4.9-18.53-4.91-18.49z"
      />
    </svg>
  );
}

export default SvgLogoAppBarEaESq;
