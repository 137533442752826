import React, { useEffect, useState } from 'react';

import { Box, DataTable, Layer, Text } from 'grommet';
import styled from 'styled-components/macro';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { RiUser5Fill } from 'react-icons/ri';
import * as FirebaseService from 'api/services/Firebase';
// import ErrorMessage from 'components/ErrorMessage';
import { PageHeading2, PageHeading3 } from 'elements/PageElements';
import UserSupportForm from 'screens/UserSupportForm';
const TableBox = styled(Box)`
  overflow-x: scroll;
`;

const columnsNew = [
  {
    property: 'uid',
    header: <Text>uid</Text>,
    primary: true,
    render: (datum) => <RiUser5Fill />,
  },
  {
    property: 'contactName',
    header: <Text>Name</Text>,
  },
  {
    property: 'contactType',
    header: <Text>Type</Text>,
  },
  {
    property: 'contactStatus',
    header: <Text>status</Text>,
    primary: true,
  },
  {
    property: 'contactValue',
    header: <Text></Text>,
    primary: true,
  },
  {
    property: 'dateTimeAdded',
    header: <Text>dateTimeAdded</Text>,
    primary: true,
  },
  {
    property: 'dtAdded',
    header: <Text>UserTz</Text>,
    primary: true,
  },
  {
    property: 'addedLocal',
    header: <Text>addedLocal</Text>,
    primary: true,
  },

  {
    property: 'diffRel',
    header: <Text>diffRel</Text>,
    primary: true,
  },
];
const columnsDone = [
  {
    property: 'uid',
    header: <Text>uid</Text>,
    primary: true,
    render: (datum) => <RiUser5Fill />,
  },
  {
    property: 'contactName',
    header: <Text>Name</Text>,
  },
  {
    property: 'contactType',
    header: <Text>Type</Text>,
  },
  {
    property: 'contactStatus',
    header: <Text>status</Text>,
    primary: true,
  },
  {
    property: 'contactValue',
    header: <Text></Text>,
    primary: true,
  },
  {
    property: 'adminNote',
    header: <Text>admin</Text>,
  },
  {
    property: 'contactNote',
    header: <Text>contactNote</Text>,
  },
  {
    property: 'updatedLocal',
    header: <Text>updatedLocal</Text>,
  },
];

const columnsHold = [
  {
    property: 'uid',
    header: <Text>uid</Text>,
    primary: true,
    render: (datum) => <RiUser5Fill />,
  },
  {
    property: 'contactName',
    header: <Text>Name</Text>,
  },
  {
    property: 'contactType',
    header: <Text>Type</Text>,
  },
  {
    property: 'contactStatus',
    header: <Text>status</Text>,
    primary: true,
  },
  {
    property: 'contactValue',
    header: <Text></Text>,
    primary: true,
  },
  {
    property: 'adminNote',
    header: <Text>admin</Text>,
  },
  {
    property: 'contactNote',
    header: <Text>contactNote</Text>,
  },
  {
    property: 'updatedLocal',
    header: <Text>updatedLocal</Text>,
  },
];
const UserSupport = () => {
  const [userList, setUserList] = useState([]);
  // const [error, setError] = useState();
  const [currentItem, setCurrentItem] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const onDataChange = (items) => {
    let updatedUserList = [];
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advancedFormat);
    dayjs.extend(relativeTime);
    items.forEach((item) => {
      const key = item.key;
      const data = item.val();
      const {
        contactName,
        contactType,
        contactValue,
        dateTimeAdded,
        timestampAdded,
        contactStatus,
        contactNote,
        timestampUpdated,
        adminName,
      } = data;
      const userStatus = contactStatus ? contactStatus : 'new';
      const userNote = contactNote ? contactNote : '';
      const tsUpdated = timestampUpdated ? timestampUpdated : dayjs(Date.now());
      const adminNote = adminName ? adminName : '';
      const dtAdded = dateTimeAdded ? dayjs(dateTimeAdded).format('z') : '';

      //const currentTz = dayjs.tz.guess();
      // console.log(`currentTz=${currentTz}`);

      const updatedUtcDate = dayjs(tsUpdated).utc();
      const updatedServer = updatedUtcDate.format();
      const updatedLocal = updatedUtcDate
        .local()
        .format('ddd, MMM D, YYYY h:mm A');

      const addedUtcDate = dayjs(timestampAdded).utc();
      const addedServer = addedUtcDate.format();
      const addedLocal = addedUtcDate.local().format('ddd, MMM D, YYYY h:mm A');

      const nowUtcDate = dayjs(Date.now()).utc();
      const nowServer = nowUtcDate.format();
      const nowLocal = nowUtcDate.local().format('ddd, MMM D, YYYY h:mm A');
      const diffRel = addedUtcDate.fromNow();
      const diff = nowUtcDate.diff(addedUtcDate, 'hour');
      updatedUserList.push({
        uid: key,
        contactName,
        contactType,
        contactValue,
        timestampAdded,
        addedServer,
        addedLocal,
        nowServer,
        nowLocal,
        diff,
        diffRel,
        dateTimeAdded,
        dtAdded,
        contactStatus: userStatus,
        contactNote: userNote,
        updatedLocal,
        updatedServer,
        adminNote,
      });
    });

    setUserList(updatedUserList);
  };
  useEffect(() => {
    FirebaseService.streamHelpContacts().on('value', onDataChange);

    return () => {
      FirebaseService.streamHelpContacts().off('value', onDataChange);
    };
  }, []);

  const viewRecordModal = (item) => {
    console.log(`item=> ${JSON.stringify(item)}`);
    const { uid, contactName, contactType, contactValue, contactStatus } = item;
    setCurrentItem({
      uid,
      contactName,
      contactType,
      contactValue,
      contactStatus,
    });
    setModalOpen(true);
  };

  // const updateRecord = (contact) => {
  //   const { uid } = currentItem;
  //   console.log(`uid-${uid}`);
  //   return FirebaseService.updateHelpContact(uid, contact);
  // };

  const onModalClose = () => {
    setCurrentItem();
    setModalOpen(undefined);
  };

  return (
    <>
      <Box full gap="medium" pad="medium">
        <PageHeading2>User Support</PageHeading2>

        <PageHeading3>New Users</PageHeading3>
        <TableBox>
          <DataTable
            columns={columnsNew}
            data={userList.filter((item) => item.contactStatus === 'new')}
            onClickRow={(event) => viewRecordModal(event.datum)}
          />
        </TableBox>
        <PageHeading3>Hold Users</PageHeading3>
        <TableBox>
          <DataTable
            columns={columnsHold}
            data={userList.filter((item) => item.contactStatus === 'hold')}
            onClickRow={(event) => viewRecordModal(event.datum)}
          />
        </TableBox>
        <PageHeading3>Done Users</PageHeading3>
        <TableBox>
          <DataTable
            columns={columnsDone}
            data={userList.filter((item) => item.contactStatus === 'done')}
          />
        </TableBox>
      </Box>
      {modalOpen && (
        <Layer position="center" onEsc={onModalClose}>
          <Box
            pad="medium"
            gap="small"
            background={{
              color: 'global-primary-main',
              dark: true,
            }}
          >
            <UserSupportForm
              initialValues={currentItem}
              onFormComplete={onModalClose}
            />
          </Box>
        </Layer>
      )}
    </>
  );
};

export default UserSupport;
