import React from 'react';
// import styled from 'styled-components/macro';
// import { Router } from '@reach/router';
import { Box } from 'grommet';
const Dashboard = () => {
  return (
    <Box height={{ min: '500px' }} justify="center">
      Welcome to Ea Rep Resource Admin Hub
    </Box>
  );
};

export default Dashboard;
