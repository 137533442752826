import * as React from 'react';

function SvgLogoProgramExpress(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 410.3 103.9"
      {...props}
    >
      <path
        d="M0 21.9h44.8v9.5H10.6v15.7h30.3v9.5H10.6v16.2h34.6v9.5H0zm135.4 0h23.8c14.1 0 22.9 8 22.9 20.2v.2c0 13.5-10.9 20.6-24.2 20.6h-12v19.4h-10.6V21.9zm22.9 31.4c8 0 13-4.5 13-10.8v-.2c0-7.1-5.1-10.8-13-10.8H146v21.7h12.3zM193 21.9h26.9c7.6 0 13.5 2.2 17.4 6a18.3 18.3 0 015 13v.2c0 9.9-6 15.9-14.3 18.3l16.3 22.9h-12.6l-14.8-21.1h-13.3v21.1H193V21.9zm26.2 29.9c7.6 0 12.4-4 12.4-10.1v-.2c0-6.5-4.7-10-12.5-10h-15.4v20.3zm36.2-29.9h44.8v9.5H266v15.7h30.3v9.5H266v16.2h34.6v9.5h-45.2V21.9zm53.8 51.6l6.4-7.6c5.8 5 11.6 7.9 19.1 7.9 6.6 0 10.7-3.1 10.7-7.6V66c0-4.3-2.4-6.6-13.6-9.2-12.9-3.1-20.1-6.9-20.1-18v-.3c0-10.4 8.6-17.5 20.6-17.5a33.49 33.49 0 0121.9 7.6l-5.7 8c-5.4-4.1-10.9-6.2-16.4-6.2-6.2 0-9.8 3.2-9.8 7.2v.2c0 4.7 2.8 6.7 14.4 9.5C349.5 50.4 356 55 356 65v.2c0 11.3-8.9 18-21.6 18a37.88 37.88 0 01-25.2-9.7zm54.3 0l6.4-7.6c5.8 5 11.6 7.9 19.1 7.9 6.6 0 10.7-3.1 10.7-7.6V66c0-4.3-2.4-6.6-13.6-9.2-12.9-3.1-20.1-6.9-20.1-18v-.3c0-10.4 8.6-17.5 20.6-17.5a33.49 33.49 0 0121.9 7.6l-5.7 8c-5.4-4.1-10.9-6.2-16.4-6.2-6.2 0-9.8 3.2-9.8 7.2v.2c0 4.7 2.8 6.7 14.4 9.5 12.8 3.1 19.3 7.7 19.3 17.7v.2c0 11.3-8.9 18-21.6 18a38.26 38.26 0 01-25.2-9.7z"
        fill="#231f20"
      />
      <path
        fill="#e54126"
        fillRule="evenodd"
        d="M63.2 70.6l5.9-10.4L75 49.7l6.2 10.4 6.1 10.3-12.1.1-12 .1z"
      />
      <path
        fill="#f19f1f"
        fillRule="evenodd"
        d="M87.3 70.4l-12.1.1-12 .1L69.3 81l6.1 10.4 6-10.5 5.9-10.5z"
      />
      <path
        fill="#f19f1f"
        fillRule="evenodd"
        d="M51.4 91.6l5.9-10.5 5.9-10.5L69.3 81l6.1 10.4-12 .1-12 .1z"
      />
      <path
        fill="#1d459c"
        fillRule="evenodd"
        d="M106.6 62.2l6.1 10.4 6.1 10.4-12 .1-12 .1 5.9-10.5 5.9-10.5z"
      />
      <path
        fill="#24368d"
        fillRule="evenodd"
        d="M107 103.9l5.9-10.5 5.9-10.4-12 .1-12 .1 6.1 10.3 6.1 10.4z"
      />
      <path
        fill="#1b2368"
        fillRule="evenodd"
        d="M118.8 83l6.1 10.3 6.1 10.4-12 .1-12 .1 5.9-10.5 5.9-10.4z"
      />
      <path
        fill="#2b4ea1"
        fillRule="evenodd"
        d="M94.8 83.2l5.9-10.5 5.9-10.5-12 .1-12.1.1 6.2 10.4 6.1 10.4z"
      />
      <path
        fill="#2a54a4"
        fillRule="evenodd"
        d="M94.4 41.5l6.1 10.3 6.1 10.4-12 .1-12.1.1 6-10.4 5.9-10.5z"
      />
      <path
        fill="#365ca9"
        fillRule="evenodd"
        d="M82.5 62.4l6-10.4 5.9-10.5-12 .1-12.1.1 6.1 10.4 6.1 10.3z"
      />
      <path
        fill="#3a6fb6"
        fillRule="evenodd"
        d="M82.2 20.7l6.1 10.4 6.1 10.4-12 .1-12.1.1 5.9-10.5 6-10.5z"
      />
      <path
        fill="#4993cf"
        fillRule="evenodd"
        d="M70.3 41.7l5.9-10.5 6-10.5-12.1.1-12 .2 6.1 10.3 6.1 10.4z"
      />
      <path
        fill="#43c1f0"
        fillRule="evenodd"
        d="M69.9 0L76 10.4l6.2 10.3-12.1.1-12 .2L64 10.5 69.9 0z"
      />
      <path
        fill="#76cee5"
        fillRule="evenodd"
        d="M58.1 21L64 10.5 69.9 0l-12 .1-12 .1L52 10.6 58.1 21z"
      />
      <path
        fill="#e54126"
        fillRule="evenodd"
        d="M111.8 30l-5.9 10.5L100 51l-6.1-10.4-6.1-10.4 12-.1 12-.1z"
      />
      <path
        fill="#e72329"
        fillRule="evenodd"
        d="M87.8 30.2l12-.1 12-.1-6.1-10.3-6.1-10.4-5.9 10.5-5.9 10.4z"
      />
      <path
        fill="#e72329"
        fillRule="evenodd"
        d="M123.7 9.1l-5.9 10.4-6 10.5-6.1-10.3-6.1-10.4 12-.1 12.1-.1z"
      />
    </svg>
  );
}

export default SvgLogoProgramExpress;
