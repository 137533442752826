import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components/macro';
import * as FirebaseService from 'api/services/Firebase';
import ErrorMessage from 'components/ErrorMessage';
import TableList from './TableList';
import { PageHeading2 } from 'elements/PageElements';
// import { GoVerified, GoUnverified } from 'react-icons/go';
// import { FaCircle, FaRegCircle } from 'react-icons/fa';
// import { VscUnverified } from 'react-icons/vsc';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const TotalRecords = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.5rem;
  font-weight: 200;
`;

// const columns = [
//   {
//     property: 'displayName',
//     header: <Text>Name</Text>,
//   },
//   {
//     property: 'email',
//     header: <Text>email</Text>,
//     primary: true,
//   },
//   {
//     property: 'emailVerified',
//     header: 'verified',
//     render: (datum) => (
//       <Box pad={{ vertical: 'xsmall' }}>
//         {datum.emailVerified ? <GoVerified /> : <VscUnverified />}
//       </Box>
//     ),
//   },
//   {
//     property: 'admin',
//     header: 'admin',
//     render: (datum) => (
//       <Box pad={{ vertical: 'xsmall' }}>
//         {datum.admin ? <FaCircle /> : <FaRegCircle />}
//       </Box>
//     ),
//   },
//   {
//     property: 'creationTime',
//     header: <Text>Created</Text>,
//   },
//   {
//     property: 'lastSignInTime',
//     header: <Text>Last SignIn</Text>,
//   },
//   {
//     property: 'lastRefreshTime',
//     header: <Text>Last Refresh</Text>,
//   },
// ];

// const TableBox = styled(Box)`
//   overflow-x: scroll;
// `;

const Reps = () => {
  const [repList, setRepList] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    FirebaseService.listAllAuthUsers()
      .then((result) => {
        // console.log(`users=${JSON.stringify(result)}`);
        const reps = result.data.users;

        const list = reps.map((item) => {
          const { metadata, email, ...fields } = item;
          const { creationTime, lastSignInTime, lastRefreshTime } = metadata;
          const isEa = email.includes('excelerateamerica.com');
          dayjs.extend(utc);
          const cTime = dayjs(creationTime)
            .utc()
            .local()
            .format('MM-DD-YYYY h:mm A');
          const lsiTime = dayjs(lastSignInTime)
            .utc()
            .local()
            .format('MM-DD-YYYY h:mm A');
          const lrTime = dayjs(lastRefreshTime)
            .utc()
            .local()
            .format('MM-DD-YYYY h:mm A');
          return {
            ...fields,
            email,
            isEa,
            creationTime: cTime,
            lastSignInTime: lsiTime,
            lastRefreshTime: lrTime,
          };
        });
        const noadmin = list.filter((rec) => !rec.isEa);
        setRepList(noadmin);
      })
      .catch((error) => {
        // Getting the Error details.
        console.log(`users=${JSON.stringify(error)}`);
        setError(JSON.stringify(error));
        // ...
      });
  }, [setRepList]);
  // Use an effect hook to subscribe to the grocery list item stream and
  // automatically unsubscribe when the component unmounts.
  // useEffect(() => {
  //   const unsubscribe = FirebaseService.streamUserList({
  //     next: (querySnapshot) => {
  //       const updatedRepList = querySnapshot.docs.map((docSnapshot) =>
  //         docSnapshot.data()
  //       );
  //       setRepList(updatedRepList);
  //     },
  //     error: () => setError('rep-list-get-fail'),
  //   });
  //   return unsubscribe;
  // }, [setRepList]);

  return (
    <Box full gap="medium" pad="medium">
      <Box direction="row" justify="between" alignContent="end">
        <PageHeading2>Rep List</PageHeading2>
        <TotalRecords>Total Records: {repList.length}</TotalRecords>
      </Box>

      {error && <ErrorMessage errorCode={error} />}

      <TableList data={repList} />
    </Box>
  );
};

export default Reps;
