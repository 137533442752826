import React, { useState } from 'react';
import { Box, DropButton, Avatar, Button } from 'grommet';

import { useRecoilValue } from 'recoil';
import { useNavigate } from '@reach/router';
import { userAtom } from 'api/store';

import { useAuth } from 'api/providers/AuthProvider';
import styled from 'styled-components/macro';

import { breakWidths, uiConstants } from 'styles';

import { LogoAppBarEaESq } from 'elements/Logos';
// export const UserMenu = ({ user = {}, items = [], ...rest }) => (
//   <Menu
//     dropAlign={{ top: 'bottom', right: 'right' }}
//     icon={false}
//     items={items.map((item) => ({
//       ...item,
//       label: <Text size="small">{item.label}</Text>,
//       onClick: () => {}, // no-op
//     }))}
//     label={<Avatar name={user.name} url={user.thumbnail} />}
//     {...rest}
//   />
// );

const ResourceLabel = styled.div`
  font-size: 1.3rem;
  line-height: 1.3rem;
`;

const SideButton = styled(Button)`
  border-radius: 3px;
  text-align: center;
`;
const AppHeaderBoxArea = styled(Box)`
  justify-content: space-between;
  @media (min-width: ${breakWidths.sm}) {
    justify-content: center;
  }
`;
export const AdminAppHeaderPublic = () => {
  return (
    <AppHeaderBoxArea
      fill="horizontal"
      background="light-0"
      direction="row"
      align="center"
      pad={{ horizontal: '1rem', vertical: 'none' }}
      height={uiConstants.appBarHeight}
    >
      <Box width="160px" justify="center" pad="none">
        <LogoAppBarEaESq width="100%" height="100%" />
      </Box>
      <Box justify="center" pad={{ horizontal: 'small', vertical: 'none' }}>
        <ResourceLabel>ADMIN Resource Hub</ResourceLabel>
      </Box>
    </AppHeaderBoxArea>
  );
};
// export const AppHeaderPublic = () => {
//   return (
//     <AppHeaderBoxArea
//       fill="horizontal"
//       background="light-0"
//       direction="row"
//       align="center"
//       pad={{ horizontal: '1rem', vertical: 'none' }}
//       height={uiConstants.appBarHeight}
//     >
//       <Box direction="row" align="center" pad="none">
//         <Box
//           direction="row"
//           pad="none"
//           focusIndicator={false}
//           hoverIndicator={false}
//         >
//           <Box width="160px" justify="center" pad="none">
//             <LogoAppBarEaFull width="100%" height="100%" />
//           </Box>
//           <Box justify="center" pad={{ horizontal: 'small', vertical: 'none' }}>
//             <ResourceLabel>Resource Hub</ResourceLabel>
//           </Box>
//         </Box>
//       </Box>
//     </AppHeaderBoxArea>
//   );
// };
const AppSidebar = ({ onAction, onNav }) => {
  return (
    <Box
      elevation="small"
      direction="column"
      justify="between"
      background={{
        color: 'light-2',
        dark: false,
      }}
    >
      <Box justify="start" direction="column" align="center">
        <Box key="signout" pad={{ horizontal: 'small', vertical: 'xsmall' }}>
          <SideButton
            align="center"
            fill
            secondary
            label="SIGN-OUT"
            onClick={onAction}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const AdminAppHeader = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useRecoilValue(userAtom);
  const { signout } = useAuth();

  const navigate = useNavigate();

  const signUserOut = () => {
    signout()
      .then(function () {
        navigate('/landing');
      })
      .catch(function (error) {
        // An error happened.
        console.log(`error`);
      });
  };

  const homeNav = () => {
    if (currentUser) {
      navigate('/');
    }
  };
  const navTo = (slug) => {
    console.log(`slug ${slug}`);
    navigate(slug);
  };

  return (
    <Box
      fill="horizontal"
      background="global-background-1"
      direction="row"
      align="center"
      justify="end"
      pad={{ horizontal: '1rem', vertical: 'none' }}
      height={uiConstants.appBarHeight}
      elevation="medium"
    >
      <Box direction="row" align="center" pad="none">
        <Box
          direction="row"
          pad="none"
          focusIndicator={true}
          onClick={homeNav}
          justify="start"
        >
          <Box height="48px" width="48px" justify="center" pad="none">
            <LogoAppBarEaESq fill="transparent" width="100%" height="100%" />
          </Box>
          <Box justify="center" pad={{ horizontal: 'small', vertical: 'none' }}>
            <ResourceLabel>Admin Rep Resource Hub</ResourceLabel>
          </Box>
        </Box>
      </Box>

      <Box flex={true} />
      {currentUser && (
        <Box
          direction="row"
          pad="none"
          gap="small"
          justify="end"
          align="center"
        >
          <DropButton
            open={open}
            dropAlign={{ top: 'bottom', right: 'right' }}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setOpen(false);
            }}
            background="transparent"
            dropContent={<AppSidebar onAction={signUserOut} onNav={navTo} />}
          >
            <Box
              dropAlign={{ right: 'right', top: 'top' }}
              responsive={false}
              direction="row"
              align="center"
              gap="small"
            >
              <Avatar size="appBar" background="global-primary-main">
                {currentUser.initials}
              </Avatar>
            </Box>
          </DropButton>
        </Box>
      )}
    </Box>
  );
};
