export const breakpoints = {
  xxs: 48,
  xs: 375,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
};

export const uiConstants = {
  appBarHeight: '4rem',
};
export const deviceView = (width) => {
  if (width > breakpoints.xl) {
    return 'desktop';
  }
  if (width > breakpoints.md) {
    return 'tablet';
  }
  return 'mobile';
};
export const breakWidths = {
  xxs: `${breakpoints.xxs}px`,
  xs: `${breakpoints.xs}px`,
  sm: `${breakpoints.sm}px`,
  md: `${breakpoints.md}px`,
  lg: `${breakpoints.lg}px`,
  xl: `${breakpoints.xl}px`,
  xxl: `${breakpoints.xxl}px`,
};

export const mediaMinBreakWidths = {
  xsmall: `@media (min-width: ${breakWidths.xs})`,
  small: `@media (min-width: ${breakWidths.sm})`,
  medium: `@media (min-width: ${breakWidths.md})`,
  large: `@media (min-width: ${breakWidths.lg})`,
  xlarge: `@media (min-width: ${breakWidths.xl})`,
  xxlarge: `@media (min-width: ${breakWidths.xxl})`,
};
export const mediaMinDevice = {
  tablet: `@media (min-width: ${breakWidths.sm})`,
  desktop: `@media (min-width: ${breakWidths.xl})`,
};
export const mediaMin = {
  xsmall: `@media (min-width: ${breakpoints.xs}px)`,
  small: `@media (min-width: ${breakpoints.sm}px)`,
  medium: `@media (min-width: ${breakpoints.md}px)`,
  large: `@media (min-width: ${breakpoints.lg}px)`,
  xlarge: `@media (min-width: ${breakpoints.xl}px)`,
  xxlarge: `@media (min-width: ${breakpoints.xxl}px)`,
};
export const breaks = {
  xsmall: {
    value: `${breakpoints.xs}`,
  },
  small: {
    value: `${breakpoints.sm}`,
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '6px',
      xlarge: '12px',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '2px',
      xsmall: '3px',
      small: '6px',
      medium: '12px',
      large: '24px',
      xlarge: '48px',
    },
    size: {
      xxsmall: '24px',
      xsmall: '48px',
      small: '96px',
      medium: '192px',
      large: '384px',
      xlarge: '768px',
      full: '100%',
    },
  },
  medium: {
    value: `${breakpoints.md}`,
    edgeSize: {
      none: '0px',
      small: '12px',
      medium: '24px',
      large: '48px',
    },
  },
  large: {
    value: `${breakpoints.lg}`,
    edgeSize: {
      none: '0px',
      small: '12px',
      medium: '24px',
      large: '48px',
    },
  },
  xlarge: {
    value: `${breakpoints.xl}`,
    edgeSize: {
      none: '0px',
      small: '12px',
      medium: '24px',
      large: '48px',
    },
  },
};

export const deviceWidth = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: `${breakpoints.sm}px`,
  tablet: `${breakpoints.md}px`,
  tabletL: `${breakpoints.lg}px`,
  laptop: '1024px',
  laptopL: `${breakpoints.xl}px`,
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${deviceWidth.mobileS})`,
  mobileM: `(min-width: ${deviceWidth.mobileM})`,
  mobileL: `(min-width: ${deviceWidth.mobileL})`,
  tablet: `(min-width: ${deviceWidth.tablet})`,
  tabletL: `(min-width: ${deviceWidth.tabletL})`,
  laptop: `(min-width: ${deviceWidth.laptop})`,
  laptopL: `(min-width: ${deviceWidth.laptopL})`,
  desktop: `(min-width: ${deviceWidth.desktop})`,
  desktopL: `(min-width: ${deviceWidth.desktop})`,
};

export const deviceMin = {
  mobileS: `(min-width: ${deviceWidth.mobileS})`,
  mobileM: `(min-width: ${deviceWidth.mobileM})`,
  mobileL: `(min-width: ${deviceWidth.mobileL})`,
  tablet: `(min-width: ${deviceWidth.tablet})`,
  tabletL: `(min-width: ${deviceWidth.tabletL})`,
  laptop: `(min-width: ${deviceWidth.laptop})`,
  laptopL: `(min-width: ${deviceWidth.laptopL})`,
  desktop: `(min-width: ${deviceWidth.desktop})`,
  desktopL: `(min-width: ${deviceWidth.desktop})`,
};
export const deviceMax = {
  mobileS: `(max-width: ${deviceWidth.mobileS})`,
  mobileM: `(max-width: ${deviceWidth.mobileM})`,
  mobileL: `(max-width: ${deviceWidth.mobileL})`,
  tablet: `(max-width: ${deviceWidth.tablet})`,
  tabletL: `(max-width: ${deviceWidth.tabletL})`,
  laptop: `(max-width: ${deviceWidth.laptop})`,
  laptopL: `(max-width: ${deviceWidth.laptopL})`,
  desktop: `(max-width: ${deviceWidth.desktop})`,
  desktopL: `(max-width: ${deviceWidth.desktop})`,
};

// export const mediaMinDevice = {
//   mobileS: `@media (min-width: ${deviceWidth.mobileS})`,
//   mobileM: `@media (min-width: ${deviceWidth.mobileM})`,
//   mobileL: `@media (min-width: ${deviceWidth.mobileL})`,
//   tablet: `@media (min-width: ${deviceWidth.tablet})`,
//   tabletL: `@media (min-width: ${deviceWidth.tabletL})`,
//   laptop: `@media (min-width: ${deviceWidth.laptop})`,
//   laptopL: `@media (min-width: ${deviceWidth.laptopL})`,
//   desktop: `@media (min-width: ${deviceWidth.desktop})`,
//   desktopL: `@media (min-width: ${deviceWidth.desktop})`,
// };
export const mediaMaxDevice = {
  mobileS: `@media (max-width: ${deviceWidth.mobileS})`,
  mobileM: `@media (max-width: ${deviceWidth.mobileM})`,
  mobileL: `@media (max-width: ${deviceWidth.mobileL})`,
  tablet: `@media (max-width: ${deviceWidth.tablet})`,
  tabletL: `@media (max-width: ${deviceWidth.tabletL})`,
  laptop: `@media (max-width: ${deviceWidth.laptop})`,
  laptopL: `@media (max-width: ${deviceWidth.laptopL})`,
  desktop: `@media (max-width: ${deviceWidth.desktop})`,
  desktopL: `@media (max-width: ${deviceWidth.desktop})`,
};
// export function mediaTag(rule, device) {
//   return `'@media (max-width: ${size[device]})'`;
// }
