import React, { useState } from 'react';
import * as yup from 'yup';
// import styled from 'styled-components/macro';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as FirebaseService from 'api/services/Firebase';

import { TextInput, Select } from 'grommet';
import { ErrorMessage } from '@hookform/error-message';
import { capSentence } from 'utils/form';

import {
  FormFieldCustom,
  SubmitBtn,
  FieldTextApiErrors,
  FormFieldTextError,
  FormBox,
  FormSubmitBox,
  FormErrorsBox,
  FormCtaBtn,
  FormCtaBtnSecondary,
  FormContent,
} from 'elements/FormElements';

const UserSupportSchema = yup.object().shape({
  contactName: yup.string().required(),
  contactType: yup.string().required(),
  contactValue: yup.string().required(),
  contactStatus: yup.string().required(),
  contactNote: yup.string().required(),
});

const UserSupportForm = ({ initialValues, onFormComplete }) => {
  const [isWorking, setIsWorking] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [apiErrors, setApiErrors] = useState();

  // const { handleSubmit, register, errors } = useForm();
  const { errors, control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(UserSupportSchema),
  });

  const onUpdateSubmit = async (data) => {
    console.log(`JSON-> ${JSON.stringify(data)}`);

    setIsWorking(true);
    setApiErrors();
    const userId = initialValues.uid;
    console.log(`userId-> ${userId}`);
    const {
      contactName,
      contactType,
      contactValue,
      contactStatus,
      contactNote,
    } = data;
    try {
      await FirebaseService.updateHelpContact(userId, {
        contactName,
        contactType,
        contactValue,
        contactStatus,
        contactNote,
      });
      setIsWorking(false);
      setFormComplete(true);
    } catch (error) {
      const msg = capSentence(error.message);
      setApiErrors(msg);
      setIsWorking(false);
    }
  };

  if (formComplete) {
    return (
      <FormBox>
        <FormContent>Success!</FormContent>
        <FormCtaBtn label="CONTINUE" onClick={onFormComplete} />
      </FormBox>
    );
  }

  return (
    <FormBox as="form" onSubmit={handleSubmit(onUpdateSubmit)}>
      <FormFieldCustom
        required
        label="Name"
        fieldInput={
          <Controller
            name="contactName"
            control={control}
            defaultValue={initialValues.contactName}
            render={({ onChange, value }) => (
              <TextInput value={value} onChange={onChange} />
            )}
          />
        }
        fieldError={
          <ErrorMessage
            errors={errors}
            name="contactName"
            render={({ message }) => (
              <FormFieldTextError>{capSentence(message)}</FormFieldTextError>
            )}
          />
        }
      />
      <FormFieldCustom
        required
        label="Contact Type"
        fieldInput={
          <Controller
            name="contactType"
            control={control}
            defaultValue={initialValues.contactType}
            render={({ onChange, value }) => (
              <TextInput value={value} onChange={onChange} />
            )}
          />
        }
        fieldError={
          <ErrorMessage
            errors={errors}
            name="contactType"
            render={({ message }) => (
              <FormFieldTextError>{capSentence(message)}</FormFieldTextError>
            )}
          />
        }
      />

      <FormFieldCustom
        required
        label="Contact Value"
        fieldInput={
          <Controller
            name="contactValue"
            control={control}
            defaultValue={initialValues.contactValue}
            render={({ onChange, value }) => (
              <TextInput value={value} onChange={onChange} />
            )}
          />
        }
        fieldError={
          <ErrorMessage
            errors={errors}
            name="contactValue"
            render={({ message }) => (
              <FormFieldTextError>{capSentence(message)}</FormFieldTextError>
            )}
          />
        }
      />
      <FormFieldCustom
        required
        label="Contact Status"
        fieldInput={
          <Controller
            name="contactStatus"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                name={name}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                value={value}
                options={[
                  { label: 'new', value: 'new' },
                  { label: 'done', value: 'done' },
                  { label: 'hold', value: 'hold' },
                ]}
                onChange={({ value: nextValue }) => onChange(nextValue)}
              />
            )}
          />
        }
        fieldError={
          <ErrorMessage
            errors={errors}
            name="contactStatus"
            render={({ message }) => (
              <FormFieldTextError>{capSentence(message)}</FormFieldTextError>
            )}
          />
        }
      />
      <FormFieldCustom
        required
        label="Contact Note"
        fieldInput={
          <Controller
            name="contactNote"
            control={control}
            defaultValue={initialValues.contactNote}
            render={({ onChange, value }) => (
              <TextInput value={value} onChange={onChange} />
            )}
          />
        }
        fieldError={
          <ErrorMessage
            errors={errors}
            name="contactNote"
            render={({ message }) => (
              <FormFieldTextError>{capSentence(message)}</FormFieldTextError>
            )}
          />
        }
      />

      {apiErrors && (
        <FormErrorsBox>
          <FieldTextApiErrors>{apiErrors}</FieldTextApiErrors>
        </FormErrorsBox>
      )}

      <FormSubmitBox direction="row" justify="start">
        <SubmitBtn isWorking={isWorking} label="UPDATE" />
        <FormCtaBtnSecondary label="CANCEL" onClick={onFormComplete} />
      </FormSubmitBox>
    </FormBox>
  );
};

export default UserSupportForm;
