import React, { useEffect, useRef } from 'react';
import { Location } from '@reach/router';

const defaultAction = () => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};

export const OnRouteChanger = ({ location, action }) => {
  const locationRef = useRef('');
  useEffect(() => {
    if (location.pathname !== locationRef.current) {
      locationRef.current = location.pathname;

      action();
    }
  });

  return null;
};

const RouterScrollReset = ({ action = defaultAction }) => {
  return (
    <Location>
      {({ location }) => <OnRouteChanger location={location} action={action} />}
    </Location>
  );
};

export default RouterScrollReset;
