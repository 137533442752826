import palette from './palette';

const baseSpacing = 8;
// export const breakpoints = {
//   xxs: 48,
//   xs: 375,
//   sm: 640,
//   md: 768,
//   lg: 1024,
//   xl: 1280,
//   xxl: 1440,
// };
const theme = {
  name: 'earep-info',
  rounding: 4,
  spacing: `${baseSpacing}`,
  defaultMode: 'light',
  global: {
    font: {
      size: '1.125rem',
      height: '1.35rem',
      family: 'Lato',
    },
    colors: { ...palette },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '12px',
      xlarge: '24px',
    },
    breakpoints: {
      small: {
        value: 640,
        edgeSize: {
          none: '0px',
          small: '0.375rem',
          medium: '0.75rem',
          large: '1.5rem',
          xlarge: '3rem',
          xxlarge: '4rem',
        },
      },
      medium: {
        value: 768,
        edgeSize: {
          none: '0px',
          small: '0.375rem',
          medium: '0.75rem',
          large: '1.5rem',
          xlarge: '3rem',
          xxlarge: '4rem',
        },
      },
      // medium: {
      //   value: 768,
      //   edgeSize: {
      //     none: '0px',
      //     small: '0.75rem',
      //     medium: '1.5rem',
      //     large: '3rem',
      //     xlarge: '4rem',
      //     xxlarge: '5rem',
      //   },
      // },
    },

    // deviceBreakpoints: {
    //   phone: 'small',
    //   tablet: 'medium',
    //   computer: 'large',
    // },
    control: {
      border: {
        width: '0px',
        radius: '0px',
        color: 'border',
      },
    },
    debounceDelay: 300,
    drop: {
      background: '#ffffff',
      border: {
        width: '0px',
        radius: '0px',
      },
      shadowSize: 'small',
      zIndex: '20',
    },
    edgeSize: {
      none: '0px',
      hair: '1px', // for Chart
      small: '0.75rem',
      medium: '1.5rem',
      large: '3rem',
      xlarge: '4rem',
      xxlarge: '5rem',
      responsiveBreakpoint: 'medium',
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '0px 1px 2px rgba(0, 0, 0, 0.20)',
        small: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        medium: '0px 4px 8px rgba(0, 0, 0, 0.20)',
        large: '0px 8px 16px rgba(0, 0, 0, 0.20)',
        xlarge: '0px 12px 24px rgba(0, 0, 0, 0.20)',
      },
      dark: {
        none: 'none',
        xsmall: '0px 2px 2px rgba(255, 255, 255, 0.40)',
        small: '0px 4px 4px rgba(255, 255, 255, 0.40)',
        medium: '0px 6px 8px rgba(255, 255, 255, 0.40)',
        large: '0px 8px 16px rgba(255, 255, 255, 0.40)',
        xlarge: '0px 12px 24px rgba(255, 255, 255, 0.40)',
      },
    },
    focus: {
      border: {
        color: 'transparent',
      },
      outline: {
        color: 'transparent',
        size: '0',
      },
      shadow: {
        color: 'transparent',
        size: '0',
      },
    },
    hover: {
      background: {
        dark: {
          color: 'active',
          opacity: 'medium',
        },
        light: {
          color: 'active',
          opacity: 'medium',
        },
      },
      color: {
        dark: 'white',
        light: 'black',
      },
    },
    input: {
      size: 'medium',
      weight: 400,
      padding: {
        vertical: 'xsmall',
      },
    },
    opacity: {
      strong: 0.8,
      medium: 0.4,
      weak: 0.1,
    },
    selected: {
      background: 'selected',
      color: 'white',
    },
    spacing: `${baseSpacing}px`,
    size: {
      xxxsmall: `${baseSpacing * 3}px`, // 24px
      xxsmall: `${baseSpacing * 6}px`, // 48px
      xsmall: `${baseSpacing * 8}px`, // 64px
      small: `${baseSpacing * 24}px`, // 192px
      medium: `${baseSpacing * 32}px`, // 384
      large: `${baseSpacing * 64}px`, // 768
      xlarge: `${baseSpacing * 128}px`, // 1024
      xxlarge: `${baseSpacing * 160}px`, // 1280
      xxxlarge: `${baseSpacing * 180}px`, // 1440
      full: '100%',
    },
  },

  button: {
    default: {
      background: { color: 'global-default-main' },
      color: 'global-default-contrast',
      border: {
        color: 'global-default-main',
        width: '1px',
        radius: '0.4rem',
      },
      padding: {
        horizontal: '2.25rem',
        vertical: '0.4rem',
      },
    },
    primary: {
      font: {
        weight: 700,
      },
      background: { color: 'global-primary-main' },
      color: 'global-primary-contrast',
      border: {
        color: 'global-primary-main',
        width: '1px',
        radius: '0.4rem',
      },
      padding: {
        horizontal: '2.25rem',
        vertical: '0.4rem',
      },
    },
    secondary: {
      font: {
        weight: 700,
      },
      background: { color: 'light-0' },
      color: 'global-primary-main',
      border: {
        color: 'light-0',
        width: '1px',
        radius: '0.4rem',
      },
      padding: {
        horizontal: '1.5rem',
        vertical: '0.4rem',
      },
    },
    active: {
      background: { color: 'brand-contrast' },
      color: 'text',
      secondary: {
        background: 'none',
        border: {
          color: 'brand-contrast',
        },
      },
    },
    disabled: {
      opacity: 0.3,
      secondary: {
        border: { color: 'text-weak' },
      },
    },
    hover: {
      background: { color: 'active' },
      primary: {
        background: { color: 'global-primary-hover' },
        border: { color: 'global-primary-default' },
        color: 'global-primary-default',
      },
      secondary: {
        background: { color: 'global-secondary-hover' },
        border: { color: 'global-secondary-default' },
        color: 'global-secondary-default',
      },
    },
  },
  heading: {
    font: {
      family: 'Lato',
    },
    level: {
      1: {
        font: {},
        medium: {
          size: '3rem',
          height: '3.2rem',
          maxWidth: '1200px',
        },
      },
      2: {
        font: {},
        medium: {
          size: '36px',
          height: '43.2px',
          maxWidth: '1024px',
        },
      },
      3: {
        font: {},
        medium: {
          size: '24px',
          height: '28.8px',
          maxWidth: 'none',
        },
      },
      4: {
        font: {},
        medium: {
          size: '18px',
          height: '21.6px',
          maxWidth: 'none',
        },
      },
      5: {
        font: {},
        medium: {
          size: '14px',
          height: '16.8px',
          maxWidth: '1024px',
        },
      },
      6: {
        font: {},
        medium: {
          size: '12px',
          height: '14.4px',
          maxWidth: '1024px',
        },
      },
    },
    responsiveBreakpoint: 'medium',
    weight: 800,
  },
  anchor: {
    color: 'light-0',
    fontWeight: 800,
    textDecoration: 'none',
    hover: {
      fontWeight: 800,
      textDecoration: 'underline',
    },
  },
  avatar: {
    size: {
      appBar: '48px',
    },
    text: {
      size: {
        appBar: '20px',
      },
      fontWeight: 700,
    },
    extend: `border: 2px solid white;
            box-shadow: 2px 2px 15px 1px white;`,
  },
  box: {
    responsiveBreakpoint: 'medium',
  },
  formField: {
    border: {
      size: 'small',
      color: 'light-0',
      error: {
        color: 'status-critical',
      },
      position: 'inner',
      side: 'bottom',
    },
    content: {
      pad: '1.5rem',
    },
    disabled: {
      background: {
        color: 'status-disabled',
        opacity: 'medium',
      },
    },
    error: {
      color: 'status-error',
      margin: {
        vertical: '11px',
        horizontal: '23px',
      },
      size: 'small',
      weight: 400,
    },
    help: {
      color: 'status-error',
      margin: {
        start: '23px',
      },
      size: 'small',
      weight: 400,
    },
    info: {
      color: 'light-1',
      margin: {
        vertical: 'xsmall',
        horizontal: '23px',
      },
      size: 'small',
      weight: 400,
    },
    label: {
      color: 'light-0',
      margin: {
        vertical: '0.375rem',
        horizontal: '0.75rem',
      },
      pad: {
        vertical: '0.375rem',
        horizontal: '0.75rem',
      },
      size: 'small',
      weight: 400,
    },
    margin: {
      bottom: '0.75rem',
    },
  },
  text: {
    xsmall: {
      size: '0.75rem',
      height: '0.9rem',
    },
    small: {
      size: '0.875rem',
      height: '1.05rem',
    },
    medium: {
      size: '1.0rem',
      height: '1.2rem',
    },
    large: {
      size: '1.125rem',
      height: '1.35rem',
    },
  },
  textInput: {
    extend: () => `
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.35rem;
      padding:  0.75rem;
      &:focus {
        box-shadow: none;
        border-color: initial;
      }
    `,
  },
  maskedInput: {
    extend: () => `
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.35rem;
    padding: 0.75rem;
      &:focus {
        box-shadow: none;
        border-color: initial;
      }
    `,
  },
  grommet: {
    extend: () => `
    overflow: visible;
    `,
  },
};

export default theme;

// edgeSize: {
//   none: '0px',
//   hair: '1px', // for Chart
//   xxsmall: '6px',
//   xsmall: '12px', // 6
//   small: '24px', // 12
//   medium: '48px', // 24
//   large: '64px', // 48
//   xlarge: '72px', // 64px
//   xxlarge: '96px',
//   responsiveBreakpoint: 'small',
// },

// breakpoints: {
//   xsmall: {
//     value: `${breakpoints.xs}`, // 375
//     borderSize: {
//       xsmall: '1px',
//       small: '2px',
//       medium: '4px',
//       large: '8px',
//       xlarge: '12px',
//     },
//     edgeSize: {
//       none: '0px',
//       hair: '1px', // for Chart
//       xxsmall: '4px',
//       xsmall: '12px', // 6
//       small: '24px', // 12
//       medium: '48px', // 24
//       large: '64px', // 48
//       xlarge: '72px', // 64px
//     },
//     size: {
//       xxsmall: `${baseSpacing}px`, // 24
//       xsmall: `${baseSpacing * 2}px`, // 48
//       small: `${baseSpacing * 4}px`, // 96
//       medium: `${baseSpacing * 8}px`, // 192
//       large: `${baseSpacing * 16}px`, // 384
//       xlarge: `${baseSpacing * 32}px`, // 768
//       full: '100%',
//     },
//   },
//   small: {
//     value: `${breakpoints.sm}`,
//     borderSize: {
//       xsmall: '2px',
//       small: '4px',
//       medium: '8px',
//       large: '10px',
//       xlarge: '14px',
//     },
//     edgeSize: {
//       none: '0px',
//       hair: '1px', // for Chart
//       xxsmall: '8px',
//       xsmall: '12px', // 6
//       small: '48px', // 12
//       medium: '64px', // 24
//       large: '72px', // 48
//       xlarge: '96px', // 64px
//     },
//     size: {
//       xxxsmall: `${baseSpacing * 3}px`, // 24px
//       xxsmall: `${baseSpacing * 6}px`, // 48px
//       xsmall: `${baseSpacing * 8}px`, // 64px
//       small: `${baseSpacing * 24}px`, // 192px
//       medium: `${baseSpacing * 32}px`, // 256
//       large: `${baseSpacing * 48}px`, // 384
//       xlarge: `${baseSpacing * 64}px`, // 768
//       full: '100%',
//     },
//   },
//   medium: {
//     value: `${breakpoints.md}`,
//   },
//   large: {
//     value: `${breakpoints.lg}`,
//   }, // anything above 'medium'
//   xlarge: {
//     value: `${breakpoints.xl}`,
//   },
//   responsiveBreakpoint: 'small',
// },

// breakpoints: {
//   medium: {
//     value: `${breakpoints.md}`,
//     borderSize: {
//       xsmall: '2px',
//       small: '4px',
//       medium: '6px',
//       large: '8px',
//       xlarge: '10px',
//     },
//     edgeSize: {
//       none: '0px',
//       hair: '1px',
//       xsmall: '4px',
//       small: '8px',
//       medium: '24px',
//       large: '48px',
//       xlarge: '64px',
//     },
//     size: {
//       xxxsmall: `${baseSpacing * 3}px`, // 24px
//       xxsmall: `${baseSpacing * 6}px`, // 48px
//       xsmall: `${baseSpacing * 8}px`, // 64px
//       small: `${baseSpacing * 24}px`, // 192px
//       medium: `${baseSpacing * 32}px`, // 256
//       large: `${baseSpacing * 48}px`, // 384
//       xlarge: `${baseSpacing * 64}px`, // 768
//       full: '100%',
//     },
//   },
// },
