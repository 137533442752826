import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import '@fontsource/lato';

import { RecoilRoot, useRecoilSnapshot } from 'recoil';

import './index.css';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import reportWebVitals from './reportWebVitals';
import App from './App';

import AuthProvider from 'api/providers/AuthProvider';
function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}
const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <DebugObserver />
        <AuthProvider>
          <App />
        </AuthProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  );
renderApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
