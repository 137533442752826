import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { RiUserStarFill, RiDashboardFill } from 'react-icons/ri';
import { BiSupport } from 'react-icons/bi';
import { TiChevronRight } from 'react-icons/ti';
import { Box, Button } from 'grommet';

import * as PATHS from 'api/paths';

const MenuWidthOpen = '250px';
const MenuWidthClose = '48px';
const MenuHeadPosition = '200px;';
const SideSlimMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: ${(props) => (props.isOpen ? MenuWidthOpen : MenuWidthClose)};
  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: easeInOutQuad;
  overflow-x: hidden;
`;
const SideSlimMenuHead = styled.div`
  position: relative;
  left: ${(props) => (props.isOpen ? MenuHeadPosition : '0')};
  transition-property: left;
  transition-duration: 1s;
  transition-timing-function: easeInOutQuad;
  padding: 8px;
`;

const SideSlimMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  /* width: MenuWidthOpen; */
  width: 100%;
  padding: 8px;
  overflow-x: hidden;
`;

const rotateOpen = css`
  transform: rotate(180deg);
`;
const rotateClose = css`
  transform: rotate(0deg);
`;
const MenuBtnLabel = styled.span`
  white-space: nowrap;
`;

const MenuBtnRotate = styled(Button)`
  transition: all 1s;
  transition-timing: ease-in-out;
  ${(props) => (props.isOpen ? rotateOpen : rotateClose)};
`;

const SideMenu = ({ open, menuAction }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <SideSlimMenu
      fill="vertical"
      background="global-primary-main"
      isOpen={showSidebar}
    >
      <SideSlimMenuHead isOpen={showSidebar}>
        <MenuBtnRotate
          plain
          icon={<TiChevronRight size="32px" />}
          isOpen={showSidebar}
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </SideSlimMenuHead>

      <SideSlimMenuItem>
        <Button
          plain
          icon={<RiDashboardFill color="dark-1" size="32px" />}
          label={<MenuBtnLabel>DASHBOARD</MenuBtnLabel>}
          onClick={() => menuAction(PATHS.DASHBOARD)}
        />
      </SideSlimMenuItem>
      <SideSlimMenuItem>
        <Button
          plain
          icon={<RiUserStarFill color="dark-1" size="32px" />}
          label={<MenuBtnLabel>REPS</MenuBtnLabel>}
          onClick={() => menuAction(PATHS.REPS)}
        />
      </SideSlimMenuItem>
      <SideSlimMenuItem>
        <Button
          plain
          size="medium"
          icon={<BiSupport color="dark-1" size="32px" />}
          label={<MenuBtnLabel>USER SUPPORT</MenuBtnLabel>}
          onClick={() => menuAction(PATHS.USER_SUPPORT)}
        />
      </SideSlimMenuItem>
    </SideSlimMenu>
  );
};

export default SideMenu;
