import React, { useEffect, useContext, createContext } from 'react';
import { useSetRecoilState } from 'recoil';
import * as _ from 'lodash';
import { fbauth, fsdb } from 'api/services/Firebase';

import { userAtom } from 'api/store';

const ACTION_CODE_SETTINGS = { url: 'https://earep.info/program' };
const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  //const [userId, setUserId] = useState();
  //const [setUserId, userId] = useRecoilState(userAtomId);
  //const [user, setUser] = useRecoilState(userAtom);
  //const setCurrentUserId = useSetRecoilState(userAtomId);
  const setCurrentUserAtom = useSetRecoilState(userAtom);
  // const currentUserAtom = useRecoilValue(userAtom);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return fbauth.signInWithEmailAndPassword(email, password);

    // if (authUser.user.emailVerified) {
    //   setUserId(authUser.user.uid);
    // } else {
    //   setUserId(false);
    // }
    //return authUser;
    // return fbauth
    //   .signInWithEmailAndPassword(email, password)
    //   .then((authUser) => {
    //     if (authUser.emailVerified) {
    //       setUserId(authUser.user.uid);
    //       return true;
    //     } else {
    //       setUserId(false);
    //       return false;
    //     }

    //     // console.log(`userid=> ${creds}`);

    //     // return creds.user.uid;
    //   });
  };
  const signup = async (newUserData) => {
    const {
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
    } = newUserData;
    const initial1 = firstName ? firstName.charAt(0).toUpperCase() : ':';
    const initial2 = lastName ? lastName.charAt(0).toUpperCase() : ')';

    const initials = `${initial1}${initial2}`;

    const dateAdded = new Date();
    const displayName = `${firstName} ${lastName}`;

    const newUser = await fbauth.createUserWithEmailAndPassword(
      email,
      password
    );
    const currentUser = fbauth.currentUser;
    await currentUser.updateProfile({ displayName: displayName });

    const { user } = newUser;
    const { uid } = user;

    await fsdb.collection('users').doc(uid).set({
      title,
      firstName,
      lastName,
      phoneNumber,
      initials,
      email,
      dateAdded,
      uid,
    });
    //const authUser = fbauth.currentUser;
    await currentUser.sendEmailVerification(ACTION_CODE_SETTINGS);
    return true;
    // return fbauth.signOut().then(() => {
    //   setUserId(false);
    // });
  };

  const signout = async () => {
    await fbauth.signOut();
    setCurrentUserAtom(null);
  };

  const sendEmailVerification = async () => {
    const authUser = fbauth.currentUser;
    await authUser.sendEmailVerification(ACTION_CODE_SETTINGS);
    return true;
  };

  const sendPasswordResetEmail = async (email) => {
    await fbauth.sendPasswordResetEmail(email);
    return true;
  };
  const verifyActionCode = async (code) => {
    return await fbauth.applyActionCode(code);
    //const currentUser = fbauth.currentUser;
    //const { uid } = currentUser;
    //const userRef = fsdb.collection('users').doc(uid);
    // return await userRef.update({
    //   emailVerified: true,
    // });
  };

  const verifyPasswordResetCode = (code) => {
    return fbauth.verifyPasswordResetCode(code);
  };
  const confirmPasswordReset = async (code, password) => {
    await fbauth.confirmPasswordReset(code, password);
    return true;
  };

  function onAuthStateChangeHandler(callback) {
    return fbauth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        fbauth.currentUser
          .getIdTokenResult(true)
          .then((idTokenResult) => {
            if (idTokenResult.claims.admin) {
              const claims = idTokenResult.claims;
              const adminLevel = claims.level;
              const adminClaims = { isAdmin: true, adminLevel };
              return _.assign(authUser, { claims: adminClaims });
            } else {
              console.log(`NOPE`);
              return null;
            }
          })
          .then((authUser) => {
            if (authUser === null) {
              callback(null);
            } else {
              const { uid, emailVerified } = authUser;
              fsdb
                .collection('users')
                .doc(uid)
                .get()
                .then((doc) => {
                  const docData = doc.data();
                  const userId = doc.id;
                  const { initials, firstName, lastName, email } = docData;
                  callback({
                    uid: userId,
                    initials,
                    firstName,
                    lastName,
                    email,
                    emailVerified,
                    claims: authUser.claims,
                  });
                  //setCurrentUserAtom({ ...userData, emailVerified });
                });
            }
          });
      } else {
        callback(null);
      }
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChangeHandler(setCurrentUserAtom);

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [setCurrentUserAtom]);

  // Return the user object and auth methods
  return {
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
    sendEmailVerification,
    verifyActionCode,
  };
}

export default AuthProvider;
