import React from 'react';
import { Box } from 'grommet';
import { SpinnerDiamond } from 'spinners-react';
import { colors } from 'styles';
const spinning = (
  <svg
    version="1.1"
    viewBox="0 0 32 32"
    width="28px"
    height="28px"
    fill={colors['pr']}
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

export const Spinner = ({ isLarge }) => {
  if (isLarge) {
    return (
      <Box fill justify="center" align="center" pad="large">
        <SpinnerDiamond
          size="50%"
          secondaryColor={colors['light-3']}
          color={colors['light-0']}
        />
      </Box>
    );
  }
  return (
    <Box
      background={{ color: 'light-0', opacity: 'strong' }}
      align="center"
      justify="center"
      fill="horizontal"
    >
      {spinning}
    </Box>
  );
};

export const SpinnerOverlay = () => (
  <Box align="center" justify="center">
    {spinning}
  </Box>
);
