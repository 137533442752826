import React from 'react';
import { Router, Redirect, useMatch } from '@reach/router';
import { useRecoilValue } from 'recoil';
import * as _ from 'lodash';
import styled from 'styled-components/macro';
import { Grommet } from 'grommet';
import { appTheme } from 'styles';
import ViewportProvider from 'api/providers/ViewportProvider';
// import { colors } from 'styles';
// import Spinner from 'components/Spinner';
import PrivateRoutes from 'routes/PrivateRoutes';
import PublicRoutes from 'routes/PublicRoutes';
import { userAtom } from 'api/store';
import RouterScrollReset from 'components/RouterScrollReset';
// const ProgramRouter = styled(Router)`
//   display: flex;
//   flex-direction: column;
//   justify-content: start;
//   align-items: center;
//   background: ${colors['global-background-1']};
// `;

const RouterBox = styled(Router)``;

// const RouterBox2 = styled(Router)`
//   display: flex;
//   flex-direction: column;
//   justify-content: start;
//   align-items: center;
//   background: transparent;
// `;
// const PrivateRoutes = lazy(() => import('routes/PrivateRoutes'));
// const PublicRoutes = lazy(() => import('routes/PublicRoutes'));

const ProtectedRoute = ({ as: Component, ...props }) => {
  const match = useMatch('/');
  const { ...rest } = props;
  return props.adminLevel >= 5 ? (
    <Component match={match ? true : false} {...rest} />
  ) : (
    <Redirect path="/" to="/landing" noThrow />
  );
};

const PublicRoute = ({ as: Component, ...props }) => {
  const { ...rest } = props;
  return props.adminLevel < 5 ? (
    <Component {...rest} />
  ) : (
    <Redirect path="/" to="/" noThrow />
  );
};
const App = () => {
  const currentUser = useRecoilValue(userAtom);
  const adminLevel = _.get(currentUser, 'claims.adminLevel', 0);

  return (
    <ViewportProvider>
      <Grommet theme={appTheme} full>
        <RouterBox>
          <ProtectedRoute
            as={PrivateRoutes}
            path="/*"
            adminLevel={adminLevel}
          />
          <PublicRoute
            as={PublicRoutes}
            path="/landing/*"
            adminLevel={adminLevel}
          />
        </RouterBox>
        <RouterScrollReset />
      </Grommet>
    </ViewportProvider>
  );
};

export default App;
