import React from 'react';
import { deviceView } from 'styles';
const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [device, setDevice] = React.useState(deviceView(window.innerWidth));

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setDevice(deviceView(window.innerWidth));
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height, device }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height, device } = React.useContext(viewportContext);
  return { width, height, device };
};

export default ViewportProvider;
